<script>
import { startCase } from 'lodash';

export default {
    methods: {
        getCardPills(entry) {
            if (entry) {
                let pillsTags = [],
                    pillsTopics = [],
                    pillsEventType = [];

                // Only for events
                if (entry.sectionHandle === 'educationAndEvents' && entry.type && entry.type.length) {
                    pillsEventType = [{
                        text: startCase(entry.type[0].title),
                    }];
                }

                // Topics
                if (entry.topics) {
                    pillsTopics = entry.topics.map((topic) => {
                        return {
                            text: startCase(topic.title)
                        };
                    });
                }
                // Tags
                if (entry.tags) {
                    pillsTags = entry.tags.map((tag) => {
                        return {
                            text: startCase(tag.title)
                        };
                    });
                }
                return pillsEventType.concat(pillsTopics, pillsTags).slice(0, 4);
            }
            return [];
        }
    }
};
</script>
