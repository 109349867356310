<template>
    <figure class="figure">
        <slot>
            <img
                loading="lazy"
                :src="src"
                :srcset="srcset"
                :sizes="sizes ? sizes : '100vw'"
                v-bind="$attrs"
                v-on="$listeners"
            >
        </slot>

        <figcaption v-if="$slots.caption"><slot name="caption" /></figcaption>
    </figure>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: false,
            default: null
        },
        srcset: {
            type: String,
            required: false,
            default: null
        },
        sizes: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" src="./figure.less" />
