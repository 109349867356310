<template>
    <component :is="uri ? 'nuxt-link' : 'article'" :to="uri ? uri : null" class="card" :class="{'card--no-header': !$slots.header}">
        <header v-if="$slots.header" class="card__header">
            <slot name="header" />
        </header>
        <h4 class="card__title">
            <slot name="title" />
        </h4>
        <div class="card__meta">
            <slot name="meta" />
        </div>
        <div class="card__footer">
            <slot name="footer" />
        </div>
    </component>
</template>

<script>
export default {
    props: {
        uri: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" src="./card.less" />
