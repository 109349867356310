<template>
    <component :is="element" v-bind="$attrs" class="pill" v-on="$listeners">
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        element: {
            type: String,
            required: false,
            default: 'span'
        }
    }
};
</script>

<style lang="less" src="./pill.less" />
